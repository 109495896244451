@import "https://fonts.googleapis.com/css2?family=Lora&family=Open+Sans:wght@700,400&display=swap";
* {
  margin: 0;
  padding: 0;
}

@media (max-width: 1000px) {
  #app {
    width: 80%;
    margin: 1em;
  }
}

@media (max-width: 600px) {
  h1 {
    word-break: break-all;
    font-size: 3.5em !important;
  }

  .links {
    margin-top: 4em !important;
  }

  .links li {
    margin: 2em 0 !important;
  }
}

@media (prefers-color-scheme: light) {
  body {
    color: #000;
    background-color: #fff;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fff;
    background-color: #000;
  }
}

body {
  font-family: Open Sans, sans-serif;
}

#app {
  width: 60%;
  flex-flow: column;
  margin: 5em;
  display: flex;
}

.heading-container {
  width: 100%;
}

h1 {
  text-transform: uppercase;
  font-family: Open Sans, sans-serif;
  font-size: 4em;
  font-weight: 700;
}

.links {
  text-align: right;
  margin-top: 4em;
  margin-left: auto;
  font-size: 1.5em;
  list-style-type: none;
}

.links li {
  margin: .75em 0;
}

.links li:nth-child(2n+1) {
  transform: rotate(3deg);
}

.links li:nth-child(2n) {
  transform: rotate(-3deg);
}

a {
  color: inherit;
  text-decoration: none;
  transition: color .3s;
}

a:hover {
  color: #00f;
}

/*# sourceMappingURL=index.8c91d09d.css.map */
